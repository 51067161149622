import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 解决功能菜单重复点击报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location); // 路由沉余
};

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      // redirect: "/leaveToPay",
      redirect: location.href.includes("monthlyCar")
        ? "/monthlyCar"
        : location.href.includes("carInout")
          ? "/carInout"
          : location.href.includes("coupon")
            ? "/coupon"
            : location.href.includes("payInside")
              ? "/payInside"
              : "",
    },
    {
      path: "/parkingDetails",
      component: () => import("@/views/parkingDetails.vue"),
      meta: {
        title: "停车详情",
        allowBack: false,
      },
    },
    {
      path: "/carInout",
      component: () => import("@/views/loadingPage.vue"),
      meta: {
        title: "停车扫码",
      },
    },
    {
      path: "/paySuccess",
      component: () => import("@/views/outFinish.vue"),
      meta: {
        title: "支付成功",
        allowBack: false,
      },
    },
    {
      path: "/monthlyCar",
      component: () => import("@/views/monthlyCar.vue"),
      meta: {
        title: "月租授权",
      },
    },
    {
      path: "/buyMonthlyCarOrder",
      component: () => import("@/views/buyMonthlyCarOrder.vue"),
      meta: {
        title: "月租车购买",
      },
    },
    {
      path: "/tipsPage",
      component: () => import("@/views/tipsPage.vue"),
      meta: {
        title: "提示",
        allowBack: false,
      },
    },
    {
      path: "/receiveCoupon",
      component: () => import("@/views/coupon/receiveCoupon.vue"),
      // component: () => import("@/views/coupon/receiveCoupon_copy.vue"),
      meta: {
        title: "领取优惠券",
        allowBack: false,
      },
    },
    {
      path: "/resultsDisplay",
      component: () => import("@/views/resultsDisplay.vue"),
      meta: {
        title: "扫描结果",
      },
    },
    {
      path: "/scanqrcode",
      component: () => import("@/views/scanQrCode.vue"),
      meta: {
        title: "扫描结果",
      },
    },
    {
      path: "/coupon",
      component: () => import("@/views/coupon/coupon.vue"),
      meta: {
        title: "授权",
        allowBack: false,
      },
    },
    {
      path: "/leaveToPay",
      // component: () => import("@/views/coupon/leaveToPay.vue"),
      component: () => import("@/views/coupon/useCouponOrderInfo.vue"),
      meta: {
        title: "缴费出场",
      },
    },
    {
      path: "/payInside",
      component: () => import("@/views/payInside/payInside.vue"),
      meta: {
        title: "场内支付",
      },
    },
    {
      path: "/payStatus",
      component: () => import("@/views/payStatus.vue"),
      meta: {
        title: "支付状态",
      },
    },
    {
      path: "/payInsideOrderInfo",
      component: () => import("@/views/payInside/payInsideOrderInfo.vue"),
      meta: {
        title: "订单详情",
      },
    },
    {
      path: "/aggregatePay",
      component: () => import("@/views/aggregatePay/aggregatePay.vue"),
      meta: {
        title: "聚合支付",
        allowBack: false,
      },
    },
    {
      path: "/cloudBuyMonth",
      component: () => import("@/views/cloudBuyMonth/cloudBuyMonth.vue"),
      meta: {
        title: "车辆续费",
        allowBack: false,
      },
    },
    {
      path: "/newTipsPage",
      component: () => import("@/views/newTipsPage.vue"),
      meta: {
        title: "方众停车",
        allowBack: false,
      },
    },
    {
      path: "/merchantBuyCoupon",
      component: () => import("@/views/plateBuyCoupon/plateBuyCoupon.vue"),
      meta: {
        title: "购买优惠券",
        allowBack: false,
      },
    },
    {
      path: "/payWhenAddMonthCarplate",
      component: () =>
        import("@/views/cloudBuyMonth/payWhenAddMonthCarplate.vue"),
      meta: {
        title: "月卡续费",
        allowBack: false,
      },
    },
    {
      path: "/VWOLPGenerateQRcode",
      component: () => import("@/views/cloudBuyMonth/VWOLPGenerateQRcode.vue"),
      meta: {
        title: "无牌车月卡登记",
        allowBack: false,
      },
    },
    {
      path: "/noPlateRegistStatus",
      component: () => import("@/views/cloudBuyMonth/noPlateRegistStatus.vue"),
      meta: {
        title: "登记状态",
        allowBack: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  if (from.path.includes("/tipsPage")) {
    history.pushState(null, null, "#");
    window.addEventListener("popstate", () => {
      window.WeixinJSBridge.invoke("closeWindow");
    });
  }
  let allowBack = true;
  if (to.meta.allowBack !== undefined) {
    allowBack = to.meta.allowBack;
  }
  localStorage.setItem("allowBack", allowBack);
  if (!allowBack) {
    history.pushState(null, null, location.href);
  }
});

export default router;
